import React from "react";
import type { AppProps } from "next/app";
import NextHead from "next/head";
import {
  Chain,
  chain,
  WagmiConfig,
  configureChains,
  createClient,
} from "wagmi";
import { MetaMaskConnector } from "wagmi/connectors/metaMask";
import { alchemyProvider } from "wagmi/providers/alchemy";
import { publicProvider } from "wagmi/providers/public";
import { jsonRpcProvider } from "wagmi/providers/jsonRpc";
import { costonChain, songbirdChain } from "../constants/chains";
import { sepolia, mainnet } from "wagmi/chains";

const { chains, provider, webSocketProvider } = configureChains(
  [chain.foundry, mainnet, costonChain, songbirdChain, sepolia],
  [alchemyProvider({ apiKey: process.env.ALCHEMY_KEY }), publicProvider()]
);

const client = createClient({
  autoConnect: true,
  connectors: [new MetaMaskConnector({ chains })],
  provider,
  webSocketProvider,
});

export default function App({ Component, pageProps }: AppProps) {
  return (
    <WagmiConfig client={client}>
      <NextHead>
        <title>Phygital Redemptions</title>
      </NextHead>

      <Component {...pageProps} />
    </WagmiConfig>
  );
}
