import { Chain } from "wagmi";

const costonChain: Chain = {
  id: 16,
  name: "Flare Coston Testnet",
  network: "coston",
  nativeCurrency: {
    decimals: 18,
    name: "Coston FLR",
    symbol: "CFLR",
  },
  rpcUrls: {
    default: "https://rpc.coston.flr.finance/ext/bc/C/rpc",
  },
  blockExplorers: {
    default: {
      name: "Coston Explorer",
      url: "https://coston-explorer.flare.network",
    },
  },
  testnet: true,
};

const songbirdChain: Chain = {
  id: 19,
  name: "Songbird Canary Network",
  network: "songbird",
  nativeCurrency: {
    decimals: 18,
    name: "Songbird",
    symbol: "SGB",
  },
  rpcUrls: {
    default: "https://rpc.songbird.flr.finance/ext/bc/C/rpc",
  },
  blockExplorers: {
    default: {
      name: "Songbird Explorer",
      url: "https://songbird-explorer.flare.network",
    },
  },
  testnet: false,
};

const flareChain: Chain = {
  id: 14,
  name: "Flare Network",
  network: "flare",
  nativeCurrency: {
    decimals: 18,
    name: "Flare",
    symbol: "FLR",
  },
  rpcUrls: {
    default: "https://rpc.flare.flr.finance/ext/bc/C/rpc",
  },
  blockExplorers: {
    default: {
      name: "Flare Explorer",
      url: "https://flare-explorer.flare.network",
    },
  },
  testnet: true,
};

export { costonChain, songbirdChain, flareChain };
